import type { UserContext } from '@zola-helpers/client/dist/es/@types';
import { RootState } from '../../reducers';

export const getUserContext = (state: RootState): UserContext => state.user.userContext;

export const getIsFetchingUser = (state: RootState): boolean => state.user.busy;

export const getUserWeddingDate = (state: RootState): string | null | undefined =>
  state.user.userContext?.user_role_account_weddings?.[0]?.wedding_date;

export const getChecklistId = (state: RootState): number | undefined =>
  state.user.userContext?.wedding_account?.checklist_id;

export const getUserCreatedAt = (state: RootState): Date | undefined =>
  state.user.userContext?.created_at;

export const getOwnerFirstName = (state: RootState): string | undefined =>
  state.user.userContext?.user_role_account_weddings?.[0].owner_first_name;

export const getPartnerFirstName = (state: RootState): string | undefined =>
  state.user.userContext?.user_role_account_weddings?.[0].partner_first_name;

export const getWebsiteSlug = (state: RootState): string | undefined =>
  state.user.userContext?.user_role_account_weddings?.[0].slug;

export const getUserHasWebsite = (state: RootState): boolean =>
  !!state.user.userContext?.has_website;
