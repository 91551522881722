import * as ActionTypes from '../actions/types/RegistryItemActionTypes';

const initialState = {
  registryItemLoading: true,
  itemUpdated: false,
  showConfirmation: false,
};

const registryItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_REGISTRY_ITEM: {
      return { ...state, registryItemLoading: false, ...action.payload };
    }
    case ActionTypes.REQUEST_REGISTRY_ITEM: {
      return { ...state, registryItemLoading: true };
    }
    case ActionTypes.UPDATE_REGISTRY_ITEM: {
      return Object.assign({}, state, action.payload);
    }
    case ActionTypes.RECEIVE_PRODUCT_RECOMMENDATIONS: {
      return { ...state, productRecommendations: action.payload };
    }
    case ActionTypes.REGISTRY_ITEM_TOGGLE_CONFIRMATION: {
      return { ...state, showConfirmation: !state.showConfirmation };
    }
    case ActionTypes.REGISTRY_ITEM_UPDATED: {
      return { ...state, itemUpdated: true };
    }
    case ActionTypes.RESET_ITEM_DRAWER: {
      return { ...state, itemUpdated: false, showConfirmation: false };
    }
    default:
      return state;
  }
};

export default registryItemReducer;
