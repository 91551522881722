import * as ActionTypes from '../actions/types/SearchActionTypes';

const initialState = {
  couplesResults: [],
  productsSuggestionsResults: [],
  word: '',
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_PRODUCTS_SUGGESTIONS: {
      return Object.assign({}, state, { busy: true });
    }
    case ActionTypes.RECEIVE_PRODUCTS_SUGGESTIONS: {
      return Object.assign({}, state, {
        busy: false,
        productsSuggestionsResults: action.payload,
      });
    }
    case ActionTypes.REQUEST_COUPLES: {
      return Object.assign({}, state, { busy: true });
    }
    case ActionTypes.RECEIVE_COUPLES: {
      return Object.assign({}, state, {
        busy: false,
        couplesResults: action.payload,
      });
    }
    case ActionTypes.REQUEST_VENDORS: {
      return Object.assign({}, state, { busy: true });
    }
    case ActionTypes.RECEIVE_VENDORS: {
      return Object.assign({}, state, {
        busy: false,
        vendorsResults: action.payload,
      });
    }
    case ActionTypes.SET_SEARCH_WORD: {
      return Object.assign({}, state, {
        word: action.word,
      });
    }
    default:
      return state;
  }
};

export default searchReducer;
