import React from 'react';

export const SET_SIDE_DRAWER = 'zola/sideDrawer/SET_SIDE_DRAWER';

export type SideDrawerProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type SideDrawerContent = React.ComponentType<{
  isOpen: boolean;
  closeDrawer: () => void;
  props?: SideDrawerProps;
}>;

export type SetSideDrawerActionType = {
  type: typeof SET_SIDE_DRAWER;
  payload: { content: SideDrawerContent | null; props?: SideDrawerProps };
};

export type SideDrawerActionTypes = SetSideDrawerActionType;
