import type { WStoreNavView, WWeddingShopNavView } from '@zola/svc-web-api-ts-client';

export const REQUEST_CATEGORY = 'zola/nav/REQUEST_CATEGORY';
export const RECEIVE_CATEGORY = 'zola/nav/RECEIVE_CATEGORY';
export const REQUEST_WEDDING_SHOP_CATEGORY = 'zola/nav/REQUEST_WEDDING_SHOP_CATEGORY';
export const RECEIVE_WEDDING_SHOP_CATEGORY = 'zola/nav/RECEIVE_WEDDING_SHOP_CATEGORY';
export const REQUEST_TOOLS = 'zola/nav/REQUEST_TOOLS';
export const RECEIVE_TOOLS = 'zola/nav/RECEIVE_TOOLS';
export const GET_GUEST_REQUESTS = 'zola/nav/GET_GUEST_REQUESTS';
export const SET_ACTIVE_UNIVERSAL_TAB = 'zola/nav/SET_ACTIVE_UNIVERSAL_TAB';

type SetActiveUniversalTabActionType = {
  type: typeof SET_ACTIVE_UNIVERSAL_TAB;
  payload: string;
};

type RequestCategoriesActionType = {
  type: typeof REQUEST_CATEGORY;
};

type ReceiveCategoriesActionType = {
  type: typeof RECEIVE_CATEGORY;
  payload: WStoreNavView;
};

type RequestWeddingShopCategoriesActionType = {
  type: typeof REQUEST_WEDDING_SHOP_CATEGORY;
};

type ReceiveWeddingShopCategoriesActionType = {
  type: typeof RECEIVE_WEDDING_SHOP_CATEGORY;
  payload: WWeddingShopNavView;
};

type ManageGuestRequestsActionType = {
  type: typeof GET_GUEST_REQUESTS;
  payload: number;
};

export type NavActionTypes =
  | SetActiveUniversalTabActionType
  | RequestCategoriesActionType
  | ReceiveCategoriesActionType
  | RequestWeddingShopCategoriesActionType
  | ReceiveWeddingShopCategoriesActionType
  | ManageGuestRequestsActionType;
