import ApiService from '@zola-helpers/client/dist/es/http/api';
import type {
  WGetCartViewResponse,
  WStoreNavView,
  WWeddingShopNavView,
} from '@zola/svc-web-api-ts-client';

export function getCart(): Promise<WGetCartViewResponse> {
  return ApiService.get(`/website-nav/web-api/v1/cart`);
}

export function getCategories(businessUnit: string): Promise<WStoreNavView> {
  return ApiService.get(
    `/website-nav/web-api/v1/nav/get/categories${
      businessUnit ? `?businessUnit=${businessUnit}` : ''
    }`
  );
}

export function getWeddingShopCategories(): Promise<WWeddingShopNavView> {
  return ApiService.get(`/website-nav/web-api/v1/nav/navigation/wedding-shop`);
}

export function getRsvpRequestCountByWeddingAccountId(): Promise<number> {
  return ApiService.get(`/website-nav/web-api/v1/nav/wedding_account/id/count`);
}
