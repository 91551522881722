import type { UserContext } from '@zola-helpers/client/dist/es/@types';

/**
 * Created by zoechodosh on 2/17/17.
 */
export const REQUEST_USER = 'zola/user/REQUEST_USER';
export const RECEIVE_USER = 'zola/user/RECEIVE_USER';
export const IDENTIFY_USER = 'zola/user/IDENTIFY_USER';
export const IDENTIFY_GUEST_USER = 'zola/user/IDENTIFY_GUEST_USER';

type RequestUserActionType = {
  type: typeof REQUEST_USER;
};

type ReceiveUserActionType = {
  type: typeof RECEIVE_USER;
  payload: UserContext;
};

type IdentifyUserActionType = {
  type: typeof IDENTIFY_USER;
};

type IdentifyGuestUserActionType = {
  type: typeof IDENTIFY_GUEST_USER;
};

export type UserActionTypes =
  | RequestUserActionType
  | ReceiveUserActionType
  | IdentifyUserActionType
  | IdentifyGuestUserActionType;
