import * as ActionTypeValues from '../actions/types/SideDrawerActionTypes';
import type {
  SideDrawerActionTypes,
  SideDrawerContent,
  SideDrawerProps,
} from '../actions/types/SideDrawerActionTypes';

export type SideDrawerReducerStateType = {
  content: SideDrawerContent | null;
  props?: SideDrawerProps;
};

export const initialState: SideDrawerReducerStateType = {
  content: null,
};

const sideDrawerReducer = (
  state = initialState,
  action: SideDrawerActionTypes
): SideDrawerReducerStateType => {
  switch (action.type) {
    case ActionTypeValues.SET_SIDE_DRAWER: {
      return {
        ...state,
        content: action.payload.content,
        props: action.payload.props,
      };
    }
    default:
      return state;
  }
};

export default sideDrawerReducer;
