import { AnyAction } from 'redux';
import * as ActionTypes from '../actions/types/ModalActionTypes';

export type ModalType =
  | 'AUTH'
  | 'TWOFA_ACTION'
  | 'CART_MODAL_V2'
  | 'PUBLISH_REGISTRY'
  | 'SET_SHIPPING_ADDRESS'
  | 'SHIPPING_EXCLUSION'
  | 'POST_PUBLISH_SHARING'
  | 'POST_AUTH_DESKTOP_NAV_ROLLOUT'
  | 'PROMO_TERMS_MODAL'
  | 'REGISTRY_NOT_VISIBLE_MODAL'
  | 'BABY_APP_DOWNLOAD_MODAL'
  | 'BOOKING_MODAL';

export type ModalProps = Record<string, unknown> & {
  hideClose?: boolean;
  isFullPage?: boolean;
  closeCallback?: () => void;
};
export type ModalOptions = Record<string, unknown> & {
  styleOverride?: React.CSSProperties;
  ariaLabel?: string;
};

export type ModalState = {
  isVisible: boolean;
  modalType: ModalType | null;
  modalProps: ModalProps;
  modalOptions: ModalOptions;
  busy: boolean;
};
const initialState: ModalState = {
  isVisible: false,
  modalType: null,
  modalProps: {},
  modalOptions: {},
  busy: false,
};

const modalReducer = (
  state = initialState,
  action: AnyAction | undefined = undefined
): ModalState => {
  switch (action?.type) {
    case ActionTypes.HIDE_MODAL:
      return {
        ...state,
        modalType: null,
        modalProps: {},
        modalOptions: {},
        isVisible: false,
        busy: false,
      };
    case ActionTypes.SHOW_MODAL:
      return {
        ...action.payload,
        isVisible: true,
        busy: false,
      };
    case ActionTypes.IS_BUSY:
      return {
        ...state,
        busy: action.payload,
      };
    default:
      return state;
  }
};

export default modalReducer;
