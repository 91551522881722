import type { WCmsPageSummaryView } from '@zola/svc-web-api-ts-client';

export const SET_PAGE_SUMMARIES = 'zola/weddingWebsite/SET_PAGE_SUMMARIES';

export type SetPageSummariesActionType = {
  type: typeof SET_PAGE_SUMMARIES;
  payload: Record<string, WCmsPageSummaryView>;
};

export type WeddingWebsiteActionTypes = SetPageSummariesActionType;
