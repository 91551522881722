import type { UserContext } from '@zola-helpers/client/dist/es/@types';
import * as ActionTypes from '../actions/types/UserActionTypes';

export type UserReducerStateType = {
  busy: boolean;
  userContext: UserContext;
  hasIdentified: boolean;
  hasGuestIdentified: boolean;
};

export const initialState: UserReducerStateType = {
  busy: false,
  userContext: {},
  hasIdentified: false,
  hasGuestIdentified: false,
};

const userReducer = (
  state = initialState,
  action: ActionTypes.UserActionTypes
): UserReducerStateType => {
  switch (action.type) {
    case ActionTypes.REQUEST_USER: {
      return Object.assign({}, state, { busy: true });
    }
    case ActionTypes.RECEIVE_USER: {
      // window.zolaNav = window.zolaNav || {};
      let uc: UserContext & { slug?: string } = action.payload;
      const slug =
        uc.user_role_account_weddings &&
        uc.user_role_account_weddings[0] &&
        uc.user_role_account_weddings[0].slug;
      // window.zolaNav.userContext = uc;
      uc = { ...uc, slug };

      return Object.assign({}, state, { busy: false, userContext: uc });
    }
    case ActionTypes.IDENTIFY_USER: {
      return Object.assign({}, state, { hasIdentified: true });
    }
    case ActionTypes.IDENTIFY_GUEST_USER: {
      return Object.assign({}, state, { hasGuestIdentified: true });
    }
    default:
      return state;
  }
};

export default userReducer;
