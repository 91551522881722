import type { WStoreNavView, WWeddingShopNavView } from '@zola/svc-web-api-ts-client';
import * as ActionTypes from '../actions/types/NavActionTypes';

export enum UniversalTabs {
  WEDDINGS = 'WEDDINGS',
  HOME_STORE = 'HOME_STORE',
}

export type NavReducerStateType = {
  activeUniversalTab: UniversalTabs;
  categories: WStoreNavView;
  weddingShopCategories: WWeddingShopNavView;
  guestRequestNotifications: number;
  busy?: boolean;
};

export const initialState: NavReducerStateType = {
  activeUniversalTab: UniversalTabs.WEDDINGS,
  categories: {},
  weddingShopCategories: {},
  guestRequestNotifications: 0,
};

const navReducer = (
  state = initialState,
  action: ActionTypes.NavActionTypes
): NavReducerStateType => {
  switch (action.type) {
    case ActionTypes.SET_ACTIVE_UNIVERSAL_TAB: {
      return Object.assign({}, state, { activeUniversalTab: action.payload });
    }
    case ActionTypes.REQUEST_CATEGORY: {
      return Object.assign({}, state, { busy: true });
    }
    case ActionTypes.RECEIVE_CATEGORY: {
      return Object.assign({}, state, {
        busy: false,
        categories: action.payload,
      });
    }
    case ActionTypes.REQUEST_WEDDING_SHOP_CATEGORY: {
      return Object.assign({}, state, { busy: true });
    }
    case ActionTypes.RECEIVE_WEDDING_SHOP_CATEGORY: {
      return Object.assign({}, state, {
        busy: false,
        weddingShopCategories: action.payload,
      });
    }
    case ActionTypes.GET_GUEST_REQUESTS: {
      return Object.assign({}, state, { guestRequestNotifications: action.payload });
    }
    default:
      return state;
  }
};

export default navReducer;
