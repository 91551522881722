import { createReducer } from '@reduxjs/toolkit';

import { receivedInquiryList, receivedUnreadCounts } from '../actions/types/InquiryActionTypes';

export type InquiryState = {
  hasInquiries: boolean;
  unreadCount: number;
};

export const initialState: InquiryState = {
  hasInquiries: false,
  unreadCount: 0,
};

const inquiryReducer = createReducer(initialState, (builder) => {
  return builder
    .addCase(receivedUnreadCounts, (state, { payload }) => {
      const { unreadInvitesTotal, unreadTotal } = payload;
      const unreadInvites = unreadInvitesTotal ?? 0;
      const unreadMessages = unreadTotal ?? 0;
      state.unreadCount = unreadMessages + unreadInvites;
    })
    .addCase(receivedInquiryList, (state, { payload }) => {
      state.hasInquiries = Boolean(payload?.entities?.length);
    });
});

export default inquiryReducer;
