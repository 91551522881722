import _isEmpty from 'lodash/isEmpty';
import type { UserContext } from '@zola-helpers/client/dist/es/@types';
import {
  fetchUserContext,
  requestUser,
  receiveUser,
  identifyUser,
  identifyGuestUser,
  receiveIdentifyUser,
  receiveIdentifyGuestUser,
} from '@zola-helpers/client/dist/es/redux/user/userActions';
import { AppThunk } from '../reducers';
import { getUserContext as getUserContextFromStore, getIsFetchingUser } from '../selectors/user';

export function onIdentifyUser(forceIdentify?: boolean): AppThunk<void> {
  return (dispatch, getState) => {
    const userContext = getState().user.userContext || {};
    const hasIdentified = getState().user.hasIdentified || false;
    identifyUser({ userContext, hasIdentified, forceIdentify });
    dispatch(receiveIdentifyUser());
  };
}

export function onIdentifyGuestUser(forceIdentify?: boolean): AppThunk<void> {
  return (dispatch, getState) => {
    const userContext = getState().user.userContext || {};
    const { hasGuestIdentified } = getState().user;
    identifyGuestUser({ userContext, hasGuestIdentified, forceIdentify });
    dispatch(receiveIdentifyGuestUser());
  };
}

export function getUserContext(): AppThunk<Promise<void | UserContext>> {
  return (dispatch) => {
    dispatch(requestUser());
    return fetchUserContext()
      .then((json) => {
        dispatch(receiveUser(json));
        dispatch(onIdentifyUser());
        return json;
      })
      .catch((err) => console.log('web-nav getUserContext err:', err)); // eslint-disable-line no-console
  };
}

export const maybeFetchUser =
  (forceFetch = false): AppThunk<Promise<UserContext | void>> =>
  (dispatch, getState) => {
    const state = getState();
    const userInStore = getUserContextFromStore(state);
    const isFetchingUser = getIsFetchingUser(state);

    if (forceFetch || (_isEmpty(userInStore) && !isFetchingUser)) {
      return dispatch(getUserContext());
    }
    return Promise.resolve(userInStore);
  };

export { receiveUser, identifyGuestUser };
