import * as ActionTypes from '../actions/types/RegistryActionTypes';

const initialState = {
  busy: false,
  registrant_first_name: '',
  partner_registrant_first_name: '',
  settings: null,
};

const registryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_REGISTRY_DETAILS: {
      return { ...state, busy: true };
    }
    case ActionTypes.RECEIVE_REGISTRY_DETAILS: {
      return { ...state, ...action.payload, busy: false };
    }
    case ActionTypes.REQUEST_EXCLUDED_REGISTRY_PRODUCTS: {
      return { ...state, busy: true };
    }
    case ActionTypes.RECEIVE_EXCLUDED_REGISTRY_PRODUCTS: {
      return { ...state, test: action.payload, busy: false };
    }
    case ActionTypes.TOGGLE_REGISTRY_PUBLISHED: {
      return { ...state, ...action.payload, public: !state.public };
    }
    case ActionTypes.HAS_SHIPPING_ADDRESS: {
      return { ...state, ...action.payload, has_shipping_address: true };
    }
    case ActionTypes.UPDATE_REGISTRY_SETTINGS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default registryReducer;
