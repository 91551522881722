import { applyMiddleware, compose, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';

import reducers from '../reducers';

const composeEnhancers =
  typeof window !== 'undefined' &&
  window?.zola?.env &&
  (window.zola.env === 'development' || window.zola.env === 'staging')
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

let initialState = {};
if (typeof window !== 'undefined') {
  initialState = { ...(window.NAV_INITIAL_STATE || {}) };
  delete window.NAV_INITIAL_STATE;
}

const enhancers = composeEnhancers(applyMiddleware(thunk));

const finalReducers = combineReducers({
  ...reducers,
});

export default createStore(finalReducers, initialState, enhancers);
