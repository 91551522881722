import { AnyAction } from 'redux';
import * as ActionTypes from '../actions/types/AuthActionTypes';

type AuthState = {
  email: null | string;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
};
const initialState: AuthState = {
  email: null,
  isAuthenticated: false,
  isAuthenticating: false,
};

const authReducer = (state = initialState, action: AnyAction): AuthState => {
  switch (action.type) {
    case ActionTypes.LOGIN_REQUEST: {
      return {
        ...state,
        isAuthenticating: true,
      };
    }
    case ActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        email: action.payload.email,
      };
    }
    case ActionTypes.LOGIN_FAILURE: {
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        email: null,
      };
    }
    case ActionTypes.LOGOUT: {
      return {
        ...state,
        isAuthenticated: false,
        email: null,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
