import type { WCmsPageSummaryView } from '@zola/svc-web-api-ts-client';
import * as ActionTypeValues from '../actions/types/WeddingWebsiteActionTypes';
import type { WeddingWebsiteActionTypes } from '../actions/types/WeddingWebsiteActionTypes';

export type WeddingWebsiteReducerStateType = {
  pageSummariesById: Record<string, WCmsPageSummaryView>;
};

export const initialState: WeddingWebsiteReducerStateType = {
  pageSummariesById: {},
};

const weddingWebsiteReducer = (
  state = initialState,
  action: WeddingWebsiteActionTypes
): WeddingWebsiteReducerStateType => {
  switch (action.type) {
    case ActionTypeValues.SET_PAGE_SUMMARIES: {
      return {
        ...state,
        pageSummariesById: {
          ...state.pageSummariesById,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default weddingWebsiteReducer;
