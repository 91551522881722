import ApiService from '@zola-helpers/client/dist/es/http/api';
import type {
  WCreateGiftGiverReminderRequest,
  WGiftGiverReminderView,
} from '@zola/svc-web-api-ts-client';

export function createGiftGiverReminder(
  request: Omit<WCreateGiftGiverReminderRequest, 'user_object_id'>
): Promise<WGiftGiverReminderView> {
  return ApiService.post('/website-nav/web-api/v1/giftGiverReminder/create', request);
}
