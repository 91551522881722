import React from 'react';
import { Provider } from 'react-redux';
import { hydrate } from 'react-dom';
import { loadableReady } from '@loadable/component';

import store from './store';
import PublicRegistryApp from './PublicRegistryApp';

loadableReady(() => {
  const root = document.getElementById('root-nav');
  hydrate(
    <Provider store={store}>
      <PublicRegistryApp />
    </Provider>,
    root
  );
}).catch((err) => {
  // eslint-disable-next-line no-console
  console.log(err);
});
