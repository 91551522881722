import { useEffect, useCallback, useRef, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';

import type { UserContext } from '@zola-helpers/client/dist/es/@types';

import { calculateToDate } from '@zola-helpers/client/dist/es/util/dateUtils';
import { isDesktop } from '@zola-helpers/client/dist/es/util/responsive';
import { getCookie, setCookie } from '@zola-helpers/client/dist/es/util/storage';
import { getCart } from 'client/v1/nav';
import { getWeddingBySlug } from 'client/v1/publicwedding';
import { getRegistryBySlug } from 'client/v1/registry';
import { useEffectOnce } from '@zola/zola-ui/src/hooks/useEffectOnce';

export const GIFT_GIVER_COOKIE_KEY = 'giftGiverReminderModal';

export const updateGiftGiverCookie = (
  cookie: string,
  registryObjectId: string,
  override?: 1 | 2
) => {
  if (cookie) {
    const parsedCookie = JSON.parse(cookie);
    const updatedRegistryCookieValue = parsedCookie[registryObjectId] ? 2 : 1;
    setCookie(
      GIFT_GIVER_COOKIE_KEY,
      JSON.stringify({
        ...parsedCookie,
        [registryObjectId]: override || updatedRegistryCookieValue,
      })
    );
  } else {
    setCookie(GIFT_GIVER_COOKIE_KEY, JSON.stringify({ [registryObjectId]: override || 1 }));
  }
};

const useGiftGiverReminderModal = ({
  forPublicRegistry = false,
  userContext,
}: {
  forPublicRegistry?: boolean;
  userContext: UserContext;
}): {
  registryObjectId?: string;
  showGiftGiverReminderModal: boolean;
  setShowGiftGiverReminderModal: (show: boolean) => void;
} => {
  const checked = useRef(false);
  const [giftGiverCookie, setGiftGiverCookie] = useState<string>('');
  useEffectOnce(() => {
    setGiftGiverCookie(getCookie(GIFT_GIVER_COOKIE_KEY));
  });
  const [registryObjectId, setRegistryObjectId] = useState<string | undefined>();
  const [showGiftGiverReminderModal, setShowGiftGiverReminderModal] = useState(false);

  const isOwnRegistry = useCallback(
    (registryId: string) => userContext.registry_ids?.includes(registryId),
    [userContext]
  );

  const isWeddingLessThanTwoHalfWeeks = (weddingDate?: Date) => {
    if (weddingDate) {
      const today = new Date();
      const afterDate = new Date(weddingDate);
      return calculateToDate(today, afterDate, 'days') < 16;
    }
    return true;
  };

  const isModalDismissed = useCallback(
    (registryId: string) => {
      if (giftGiverCookie) {
        return (JSON.parse(giftGiverCookie)[registryId] || 0) >= 2;
      }
      return false;
    },
    [giftGiverCookie]
  );

  const isItemInCartForRegistry = async (registryId: string) => {
    const { data } = await getCart();
    const { items, registry_id } = data || {};
    const itemInCart = Boolean(items && items.length > 0);
    const itemInCartForRegistry = registryId && registryId === registry_id;
    return itemInCart && itemInCartForRegistry;
  };

  const isNoOtherModalOpen = () =>
    Boolean(
      !document.querySelectorAll(
        '#modal, #z-modal-overlay-v2, #react-joyride-portal, [role="dialog"]'
      ).length
    );

  const maybeShowModal = useCallback(async () => {
    const delay = isDesktop() ? 3 * 60 * 1000 : 2 * 60 * 1000;

    // 1. Check that we're on public registry or wedding website registry page
    const regex = forPublicRegistry ? /\/registry\/([^/]+)/ : /^\/wedding\/([^/]+)\/registry$/;
    const slug = new URL(window.location.href).pathname.match(regex)?.[1];
    if (!slug) return;

    // 2. Check that couple has a published registry
    if (!forPublicRegistry) {
      const { registry_published } = await getWeddingBySlug(slug);
      if (!registry_published) return;
    }
    const { event_date: eventDate, registry_object_id } = await getRegistryBySlug(slug);
    if (!registry_object_id) return;
    setRegistryObjectId(registry_object_id);

    // 3. Check that guest is not viewing own registry
    if (isOwnRegistry(registry_object_id)) return;

    // 4. Check that couple has wedding date and is more than or equal to 2.5 weeks out
    if (isWeddingLessThanTwoHalfWeeks(eventDate)) return;

    // 5. Check cookie that modal hasn't been dismissed or viewed more than twice for current registry
    if (isModalDismissed(registry_object_id)) return;

    // 6. Check that there are no items in cart for current registry
    if (await isItemInCartForRegistry(registry_object_id)) return;

    setTimeout(() => {
      // 7. Check that no other modal is open. If product detail modal is open at this
      // point, we simply won't show gift giver modal since guest is already showing
      // intent to buy gift in current session. Checks will run again on the next visit.
      if (isNoOtherModalOpen()) {
        updateGiftGiverCookie(giftGiverCookie, registry_object_id);
        setShowGiftGiverReminderModal(true);
      }
    }, delay);
  }, [forPublicRegistry, giftGiverCookie, isModalDismissed, isOwnRegistry]);

  useEffect(() => {
    if (!_isEmpty(userContext) && !checked.current) {
      checked.current = true;
      maybeShowModal().catch(() => null);
    }
  }, [checked, maybeShowModal, userContext]);

  return { registryObjectId, showGiftGiverReminderModal, setShowGiftGiverReminderModal };
};

export { useGiftGiverReminderModal };
