import { reducer as form } from 'redux-form';
import toasts from '@zola-helpers/client/dist/es/redux/toasts/toastsReducer';
import accountPreferencesReducer, {
  AccountPreferencesReducerStateType,
} from '@zola-helpers/client/dist/es/redux/accountPreferences/accountPreferencesReducer';

import type { WRegistrySettingsView, WRegistryView } from '@zola/svc-web-api-ts-client';

import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import modal, { ModalState } from './modal';
import auth from './auth';
import user, { UserReducerStateType } from './user';
import cart from './cart';
import nav, { NavReducerStateType } from './nav';
import search from './search';
import registry from './registry';
import item from './item';
import inquiry, { InquiryState } from './inquiry';
import sideDrawer, { SideDrawerReducerStateType } from './sideDrawer';
import weddingWebsite, { WeddingWebsiteReducerStateType } from './weddingWebsite';

interface SettingsView extends WRegistrySettingsView {
  public: boolean;
}

interface RegistryView extends WRegistryView {
  public: boolean;
  settings: SettingsView;
}
export type RootState = {
  modal: ModalState;
  cart: ReturnType<typeof cart>;
  nav: NavReducerStateType;
  user: UserReducerStateType;
  inquiry: InquiryState;
  registry: RegistryView;
  sideDrawer: SideDrawerReducerStateType;
  weddingWebsite: WeddingWebsiteReducerStateType;
  accountPreferences: AccountPreferencesReducerStateType;
};

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppDispatch = ThunkDispatch<RootState, unknown, Action<string>>;

const reducer = {
  form,
  modal,
  auth,
  user,
  cart,
  nav,
  registry,
  search,
  item,
  toasts,
  inquiry,
  sideDrawer,
  weddingWebsite,
  accountPreferences: accountPreferencesReducer,
};

export default reducer;
