import { createAction } from '@reduxjs/toolkit';
import {
  MessageStatsView,
  BasePaginationViewListInquiryView,
} from '@zola/svc-marketplace-ts-types';

const REQUEST_UNREAD_COUNTS = 'zola/couple/REQUEST_UNREAD_COUNTS';
const RECEIVED_UNREAD_COUNTS = 'zola/couple/RECEIVED_UNREAD_COUNTS';
const REQUEST_INQUIRY_LIST = 'zola/couple/REQUEST_INQUIRY_LIST';
const RECEIVED_INQUIRY_LIST = 'zola/couple/RECEIVED_INQUIRY_LIST ';

export const requestingUnreadCounts = createAction(REQUEST_UNREAD_COUNTS);
export const receivedUnreadCounts = createAction<MessageStatsView>(RECEIVED_UNREAD_COUNTS);

export const requestingInquiryList = createAction(REQUEST_INQUIRY_LIST);
export const receivedInquiryList =
  createAction<BasePaginationViewListInquiryView>(RECEIVED_INQUIRY_LIST);
